.block {
  width: 100%;
  margin: 40px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.preview {
  flex: 1 0 256px;

  display: grid;
  grid-template-areas: 'stack';
}

.imagePreview {
  border-radius: 4px;
  
  /*NOTE: This is based on @ubq/uikit checkered_background.svg. Once that is available it might be used instead of this
   * base64 string implementation instead.*/
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBvcGFjaXR5PSIwLjEiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHdpZHRoPSI0IiBoZWlnaHQ9IjQiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjgiIHdpZHRoPSI0IiBoZWlnaHQ9IjQiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjQiIHk9IjQiIHdpZHRoPSI0IiBoZWlnaHQ9IjQiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjEyIiB5PSI0IiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSJibGFjayIvPgo8cmVjdCB5PSI4IiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSI4IiB5PSI4IiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSI0IiB5PSIxMiIgd2lkdGg9IjQiIGhlaWdodD0iNCIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iMTIiIHk9IjEyIiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K);
  background-size: 24px;
}

.blurred {
  filter: blur(7px) opacity(0.2);
}

.preview > img {
  grid-area: stack;
  place-self: center;

  max-height: 500px;
}

.preview > button.primary {
  place-self: center;
}

.uploadControls {
  place-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadLogo {
  margin-block: 12px;
}

button.primary,
label.upload {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: fit-content;
  padding-inline: 16px;
  margin-block: 4px;

  background-color: var(--button-high-emphasis-bg);
  color: var(--text-light-high-emphasis);

  border: none;
  border-radius: 8px;

  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  cursor: pointer;
}

button.primary > svg:first-child,
label.upload > svg:first-child {
  /*NOTE: Negative margin is needed to visually align the icon with the
   * above set inline padding*/
  margin-inline: -4px 8px;
}

button.primary:hover,
label.upload:hover {
  background-color: var(--button-primary-hover-bg);
  box-shadow: var(--button-primary-hover-shadow);
}

button.primary:hover,
label.upload:active {
  filter: brightness(0.9);
}

button.primary:focus-visible,
label.upload:focus-visible {
  background-color: var(--button-primary-hover-bg);
  box-shadow: var(--button-primary-hover-shadow);
}

button.ghost {
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 500;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.filetypeNotice {
  margin-block: 4px;

  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4;

  letter-spacing: 0.02em;

  color: var(--imgly-black-light-color);
}

.processingOverlay {
  grid-area: stack;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  color: var(--imgly-black-light-color);
}

.processingOverlay svg {
  animation-duration: 1s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.processingOverlay > p {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  line-height: 1.45;

  color: rgba(22, 22, 23, 0.65);
}

.processingOverlay > .processMessage {
  font-size: 1.375rem;
}

.processingOverlay > .processStatus {
  font-size: 0.875rem;
}

.sampleImagesWrapper {
  height: 156px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}




.sampleImagesWrapper > span {
  flex: 0 0 20px;

  text-align: center;
  color: var(--imgly-black-light-color);
}

.sampleImages {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.sampleImage {
  border-radius: 6px;
  overflow: hidden;
}

.sampleImage,
.sampleImage > img {
  height: 120px;
  object-fit: cover;

}

.dragging {
  border: 2px dashed var(--imgly-black-light-color);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
